import React, { useState } from 'react';
import './businessprofile.css';
import { useEffect } from 'react';
import { useServiceContext } from '../contextapi/Servicecontext';

export default function BusinessProfile({}) {

    const { businessProfile, setModuleType,setUrl ,setLocationName, setDescription,totalservice ,setBusinessId ,setQueue,setLocationId , setBusinessProfile
    } = useServiceContext();
      
    const [from , setFrom] = useState();
    const [toTime ,setToTime] = useState();
    const [isHoliday , setIsHoliday] =  useState();
    const [holidayDescription , setHolidayDescription] =  useState();

    const convertTo12HourFormat = (time24) => {
        
        const [hours, minutes] = time24.split(':').map(Number);
        const period = hours >= 12 ? 'PM' : 'AM';
        const hours12 = hours % 12 || 12; // Convert 0 to 12 for 12-hour format

        return `${hours12}:${minutes.toString().padStart(2, '0')} ${period}`;
    };
    const from12Hour = from ? convertTo12HourFormat(from) : '';
    const to12Hour = toTime ? convertTo12HourFormat(toTime) : '';


    useEffect(() => {
        const timer = setTimeout(() => {
          const storedBusinessProfile = sessionStorage.getItem('businessProfile');
          
          if (storedBusinessProfile) {
            setBusinessProfile(JSON.parse(storedBusinessProfile));
            setBusinessId(businessProfile?.locationId);
            setQueue(businessProfile?.module);
            setLocationId(businessProfile?.locationId);
            setDescription(businessProfile?.listing_description);
            setUrl(businessProfile?.listing_url);
            setModuleType(businessProfile?.module);
            setLocationName(businessProfile?.locationName);
            setIsHoliday(businessProfile?.isHoliday);
            setHolidayDescription(businessProfile?.holidayDescription);
            setFrom(businessProfile?.frmTime);
            setToTime(businessProfile?.toTime);
          }
        }, 0);
      
        return () => clearTimeout(timer);
      }, []);
     

    return (
        <>
        <div className="d-flex border border-gray bg-white p-3">
            <div className="businessprofile-image ">
                <img  src= { businessProfile.locationImage ? businessProfile.locationImage : 'https://whizzdatalocal.s3-us-west-2.amazonaws.com/business_images/business_default.png'}
                    className="img-fluid me-3"
                    alt="Business Image"
                />
            </div>
            <div className="col-md-10">
                <div>
                    <div className="b-title">{businessProfile.locationName}</div>
                    <div className="card-address"><span > <i className="fa fa-location-dot"></i> {businessProfile.locationAddress + ',' + businessProfile.locationCity + ',' + businessProfile.locationState}</span> </div>
                  { isHoliday ?(<div>{holidayDescription}</div>) : (<div className="card-timing"><span> <i className="fa  fa-clock"></i> </span>  <span className='card-bold'>Working hours:</span> <span className="card-address">{from12Hour + " to " + to12Hour} </span> </div> ) }

                    <div className="card-timing"> <span><i className="fa-solid fa-gear"></i> </span> <span className='card-bold'> Total Services:</span><span className="card-address"> {totalservice} </span></div>
                </div>
            </div>
        </div>
        
        
    </>

    );
}
